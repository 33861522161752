
import { defineComponent } from 'vue'
import Field from '@/components/shared/Form/Field.vue'
import SameLineField from '@/components/shared/Form/SameLineField.vue'
import { carState } from '@/store/CarStore'
import FormVue from '../shared/Form/Form.vue'
import { FuelTypeEnum } from '@/classes/Fuel'

export default defineComponent({
  components: {
    FormVue,
    Field,
    SameLineField
  },
  data () {
    return {
      car: carState.car,
      measureType: 0,
      FuelTypeEnum
    }
  },
  computed: {
    remainingDistance () : number {
      const selectedFuel = this.car.fuelList.find((fuel) => fuel.fuelType === this.car.currentFuelType)
      return selectedFuel && selectedFuel.tankPerformance && this.car.remainingFuel ? selectedFuel.tankPerformance * this.car.remainingFuel : 0
    }
  },
  methods: {
    getFuelLabel (fuelType: string): string {
      if (fuelType === 'diesel') {
        return 'Diesel'
      } else if (fuelType === 'gasoline') {
        return 'Gasolina'
      }
      return 'Etanol'
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (!this.car.currentFuelType) {
        this.car.currentFuelType = FuelTypeEnum.gasoline
      }
    })
  }
})
