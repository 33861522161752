
// Vue
import { Options, mixins } from 'vue-class-component'
import { reactive } from 'vue'

// Interfaces
import { FuelType, FuelTypeEnum } from '@/classes/Fuel'

// Components
import Form from '@/components/shared/Form/Form.vue'
import Field from '@/components/shared/Form/Field.vue'
import SameLineField from '@/components/shared/Form/SameLineField.vue'
import CarTypeSelector from '@/components/configs/CarTypeSelector.vue'
import CarData from '@/components/configs/CarData.vue'
import { useToast } from 'vue-toastification'
import CarStore from '@/store/CarStore'
import MeasureTypeVue from '@/components/configs/MeasureType.vue'

@Options({
  components: {
    Form,
    Field,
    SameLineField,
    CarTypeSelector,
    CarData,
    MeasureTypeVue
  },
  data () {
    return {
      gasoline: reactive(new FuelType(10, 50, FuelTypeEnum.gasoline)),
      ethanol: reactive(new FuelType(10, 50, FuelTypeEnum.ethanol)),
      diesel: reactive(new FuelType(10, 50, FuelTypeEnum.diesel))
    }
  },
  methods: {
    changeFuelList () {
      if (this.car.carType === 'gasoline') {
        this.car.fuelList = [this.gasoline]
      } else if (this.car.carType === 'gasolineEthanol') {
        this.car.fuelList = [this.gasoline, this.ethanol]
      } else {
        this.car.fuelList = [this.diesel]
      }
    },
    save () {
      this.$nextTick(() => {
        localStorage.setItem('carData', JSON.stringify(this.car))
        useToast().success('Configurações salvas com sucesso.')
      })
    },
    cancel () {
      this.loadCar()
      useToast().success('Alterações canceladas.')
    }
  },
  async mounted () {
    this.loadCar()
    this.car.fuelList && this.car.fuelList.forEach((fuel: FuelType) => {
      this[fuel.fuelType] = fuel
    })
  },
  watch: {
    'car.carType': {
      deep: true,
      handler () {
        this.$nextTick(() => {
          this.changeFuelList()
        })
      }
    }
  }
})
export default class Home extends mixins(CarStore) {}
