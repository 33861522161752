
import { defineComponent, PropType } from 'vue'
import { FuelTypeInterface } from '@/classes/Fuel'
import Field from '@/components/shared/Form/Field.vue'

export default defineComponent({
  components: { Field },
  props: {
    modelValue: {
      type: Object as PropType<FuelTypeInterface>,
      default: () => ({
        kmPerformance: 10,
        tankPerformance: 50
      }),
      validator: (fuel: FuelTypeInterface) => !!fuel.kmPerformance && !!fuel.tankPerformance
    },
    type: { type: String, default: 'gasolina' }
  },
  computed: {
    value: {
      get (): FuelTypeInterface {
        return this.modelValue
      },
      set (value: number): void {
        this.$emit('update:modelValue', value)
      }
    }
  },
  methods: {
    getLabel (text: string): string {
      return this.type[this.type.length - 1] === 'a' ? `${text} na ${this.type}` : `${text} no ${this.type}`
    }
  }
})
