// Enum
export enum FuelTypeEnum {
  ethanol = 'ethanol',
  gasoline = 'gasoline',
  diesel = 'diesel',
  gasolineEthanol = 'gasolineEthanol'
}

// Interfaces
export interface FuelTypeInterface {
  kmPerformance: number
  tankPerformance: number,
  price?: number,
  fuelType: FuelTypeEnum
}

// Classes
export class FuelType implements FuelTypeInterface {
  kmPerformance: number
  tankPerformance: number
  fuelType: FuelTypeEnum
  price?: number | undefined;

  constructor (kmPerformance: number, tankPerformance: number, fuelType: FuelTypeEnum) {
    this.kmPerformance = kmPerformance
    this.tankPerformance = tankPerformance
    this.fuelType = fuelType
  }
}
