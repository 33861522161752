
import { defineComponent } from 'vue'
import SameLineField from '@/components/shared/Form/SameLineField.vue'
import { MeasureTypeEnum } from '@/classes/Car'
import { carState } from '@/store/CarStore'

export default defineComponent({
  name: 'MeasureTypeSelector',
  components: { SameLineField },
  data () {
    return {
      car: carState.car,
      MeasureTypeEnum
    }
  },
  mounted () {
    !this.car.measureType && (this.car.measureType = this.MeasureTypeEnum.tank)
  }
})
