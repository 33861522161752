
import { defineComponent } from 'vue'
import Field from '@/components/shared/Form/Field.vue'
import { carState } from '@/store/CarStore'
import FormVue from '../shared/Form/Form.vue'
import { Money3Component } from 'v-money3'
import { MeasureTypeEnum } from '@/classes/Car'

export default defineComponent({
  components: {
    FormVue,
    Field,
    Money: Money3Component
  },
  data () {
    return {
      car: carState.car,
      MeasureTypeEnum
    }
  },
  computed: {
    bestFuel () : string {
      const ethanol = this.car.fuelList.find((fuel) => fuel.fuelType === 'ethanol')
      const gasoline = this.car.fuelList.find((fuel) => fuel.fuelType === 'gasoline')
      if (gasoline && ethanol && gasoline.price && ethanol.price) {
        let normalizedEthanolPrice = 1
        if (this.car.measureType && this.car.measureType === MeasureTypeEnum.km) {
          normalizedEthanolPrice = (gasoline.kmPerformance / ethanol.kmPerformance) * ethanol.price
        } else {
          normalizedEthanolPrice = (gasoline.tankPerformance / ethanol.tankPerformance) * ethanol.price
        }
        return normalizedEthanolPrice < gasoline.price ? 'Etanol' : 'Gasolina'
      }
      return ''
    }
  },
  methods: {
    getFuelLabel (fuelType: string): string {
      if (fuelType === 'diesel') {
        return 'Diesel'
      } else if (fuelType === 'gasoline') {
        return 'Gasolina'
      }
      return 'Etanol'
    }
  }
})
