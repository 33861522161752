import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1b62978f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "carData" }
const _hoisted_2 = ["placeholder"]
const _hoisted_3 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Field, {
      label: _ctx.getLabel('Quantos km por litro seu carro rende')
    }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("input", {
          type: "text",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value.kmPerformance) = $event)),
          placeholder: _ctx.getLabel('Km/l')
        }, null, 8, _hoisted_2), [
          [
            _vModelText,
            _ctx.value.kmPerformance,
            void 0,
            { number: true }
          ]
        ])
      ]),
      _: 1
    }, 8, ["label"]),
    _createVNode(_component_Field, {
      label: _ctx.getLabel('Quanto 1/8 de tanque de seu carro rende')
    }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("input", {
          type: "text",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.value.tankPerformance) = $event)),
          placeholder: _ctx.getLabel('Km por 1/8 de tanque')
        }, null, 8, _hoisted_3), [
          [
            _vModelText,
            _ctx.value.tankPerformance,
            void 0,
            { number: true }
          ]
        ])
      ]),
      _: 1
    }, 8, ["label"])
  ]))
}