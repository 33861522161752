
import { mixins, Options } from 'vue-class-component'
import BestFuel from '@/components/calculation/BestFuel.vue'
import CarStore from '@/store/CarStore'
import RemainingFuel from '@/components/calculation/RemainingFuel.vue'
import howManyFuelVue from '@/components/calculation/howManyFuel.vue'

@Options({
  components: {
    BestFuel,
    RemainingFuel,
    howManyFuelVue
  },
  mounted () {
    if (this.car.carType === 'new') {
      this.loadCar()
    }
  },
  watch: {
    car: {
      deep: true,
      handler (newVal, oldVal) {
        newVal && oldVal.carType !== 'new' && this.saveCar()
      }
    }
  }
})
export default class Home extends mixins(CarStore) {}
