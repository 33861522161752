
import { Options, Vue } from 'vue-class-component'

@Options({
  watch: {
    $route (to) {
      this.currentPath = to.path
    }
  }
})
export default class FooterMenu extends Vue {
  currentPath = '/'
  public checkClass (type: string): string {
    if (type === this.currentPath) {
      return 'footer-selected'
    }
    return ''
  }
}
