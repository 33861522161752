
import { defineComponent } from 'vue'
import Field from '@/components/shared/Form/Field.vue'
import { carState } from '@/store/CarStore'
import FormVue from '../shared/Form/Form.vue'

export default defineComponent({
  components: {
    FormVue,
    Field
  },
  data () {
    return {
      car: carState.car,
      toGoDistance: 0
    }
  },
  computed: {
    missingLiters () : number {
      if (this.car.currentFuelType && this.car.tankSize && this.car.remainingFuel && this.toGoDistance) {
        const selectedFuel = this.car.fuelList.find((fuel) => fuel.fuelType === this.car.currentFuelType)
        if (selectedFuel) {
          const remainingDistance = selectedFuel.tankPerformance * this.car.remainingFuel
          if (remainingDistance >= this.toGoDistance) {
            return this.toGoDistance - remainingDistance
          }
          const performancePerLiter = selectedFuel.tankPerformance / (this.car.tankSize / 8)
          const missingFuel = ((this.toGoDistance - remainingDistance) / performancePerLiter)
          return missingFuel
        }
      }
      return NaN
    }
  },
  methods: {
    getFuelLabel (fuelType: string): string {
      if (fuelType === 'diesel') {
        return 'Diesel'
      } else if (fuelType === 'gasoline') {
        return 'Gasolina'
      }
      return 'Etanol'
    }
  }
})
