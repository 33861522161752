import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import Maska from 'maska'
/* eslint-disable */

const app = createApp(App)

app.use(Toast)
app.use(router)
app.use(Maska)

app.mount('#app')
