import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-header" }
const _hoisted_2 = { class: "container-body" }
const _hoisted_3 = { class: "container-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_Updater = _resolveComponent("Updater")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_FooterMenu = _resolveComponent("FooterMenu")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Header),
      _createVNode(_component_Updater)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_view)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_FooterMenu)
    ])
  ], 64))
}