import { useToast } from 'vue-toastification'
import { reactive } from 'vue'
import { Car } from '@/classes/Car'
import { Options, Vue } from 'vue-class-component'

export const carState = reactive({
  car: new Car('new', 55)
})

@Options({
  data () {
    return carState
  },
  methods: {
    loadCar: async function () {
      const temporaryCarString = localStorage.getItem('carData')
      if (temporaryCarString) {
        const car = JSON.parse(temporaryCarString)
        this.car = <Car>car
        // this.car.fuelList = []
        // car.fuelList && car.fuelList.forEach((fuel: FuelType) => {
        //   const newFuel = <FuelType> fuel
        //   this.car.fuelList.push(newFuel)
        // })
      }
    },
    saveCar: async function () {
      localStorage.setItem('carData', JSON.stringify(carState.car))
      useToast().success('Configurações salvas com sucesso.')
    }
  }
})

export default class CarStore extends Vue {}
