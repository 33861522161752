
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    modelValue: { type: String, default: 'gasoline' }
  },
  computed: {
    value: {
      get () {
        return this.modelValue
      },
      set (value: string) {
        this.$emit('update:modelValue', value)
      }
    }
  }
})
