import { FuelType, FuelTypeEnum } from './Fuel'

// Enum
export enum MeasureTypeEnum {
  km = 'km',
  tank = 'tank'
}

// Interfaces
export interface CarInterface {
  carType: string
  fuelList: Array<FuelType>
  tankSize: number
  remainingFuel?: number
  currentFuelType?: FuelTypeEnum
  measureType?: MeasureTypeEnum
}

// Classes
export class Car implements CarInterface {
  carType: string
  fuelList: Array<FuelType>
  tankSize: number
  remainingFuel?: number
  currentFuelType?: FuelTypeEnum
  measureType?: MeasureTypeEnum

  constructor (carType: string, tankSize: number) {
    this.carType = carType
    this.tankSize = tankSize
    this.fuelList = []
  }

  loadCarDataFromStorage () : void {
    const temporaryCarString = localStorage.getItem('carData')
    if (temporaryCarString) {
      const car = JSON.parse(temporaryCarString)
      this.carType = car.carType
      this.fuelList = car.fuelList
    }
  }
}
