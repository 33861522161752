
import { Options, Vue } from 'vue-class-component'
import Header from '@/components/shared/Header.vue'
import FooterMenu from '@/components/shared/FooterMenu.vue'
import Updater from './components/shared/Updater.vue'
@Options({
  components: {
    Header,
    FooterMenu,
    Updater
  }
})
export default class App extends Vue {
}
